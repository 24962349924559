<template>
	<div class="container-mobile" :class="{ 'mt-16': $isPwa() }">
		<!-- <pre>{{ timeSheets }}</pre> -->
		<div class="card" v-for="(timesheet, i) in timeSheets" :key="i">
			<!-- has-margin-bottom-->
			<div class="wrapper">
				<div class="header">
					<div class="first-col">
						<span>{{ timesheet.depName }}</span>
					</div>
					<div class="second-col d-flex justify-content-end">
						<div class="status info px-2" style="width: auto">
							<span>{{ timesheet.numTsForDep }}</span>
						</div>
					</div>
				</div>
			</div>
			<div v-for="(user, y) in timesheet.users" :key="y">
				<validate-timesheet-detail
					:user="user"
					:items="user.ts"
					:fields="fields"
					:default-toggle-detail="false"
					@validate-timesheet-detail:validated="validate"
					@validate-timesheet-detail:clicked="rowEdit"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import ValidateTimesheetDetail from './ValidateTimeSheetDetail';

export default {
	name: 'MobileViewsValidateTsMainComponent',
	components: { ValidateTimesheetDetail },
	props: {
		timeSheets: {
			type: Array,
			required: true,
			default: () => []
		},
		fields: {
			type: Array,
			required: true,
			default: () => []
		}
	},
	data() {
		return {};
	},
	methods: {
		validate(payload) {
			if (payload) {
				this.$emit('mobile-view-validate-timesheet:validated', payload);
			}
		},
		rowEdit(payload) {
			if (payload) {
				this.$emit('mobile-view-validate-timesheet:clicked', payload);
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.container-mobile .card .wrapper .header .first-col span {
	font-weight: 600;
	font-size: 1rem;
	color: rgba(6, 38, 62, 0.74);
}

.container-mobile .card .wrapper .header .second-col .status span {
	font-size: 0.85rem;
}
</style>
