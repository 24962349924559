var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.toggleTsDetail
      ? _c(
          "div",
          { staticClass: "card-inside-card" },
          [
            _c("card-list-builder", {
              attrs: {
                "use-new-version": true,
                items: _vm.items,
                fields: _vm.defaultFields,
                "has-badge": true,
                "is-badge-use-props": true,
                "badge-class": "statusClass",
                "hide-status": false,
                "badge-value": "validatedStatus",
                "toggle-mode": true,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "val",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "b-button",
                          {
                            staticClass: "btn bg-transparent border-0 pr-0",
                            attrs: { size: "sm" },
                            on: {
                              click: function ($event) {
                                return _vm.validateItem(item.id)
                              },
                            },
                          },
                          [
                            _c(_vm.getLucideIcon(_vm.ICONS.CHECK_SQUARE.name), {
                              tag: "component",
                              attrs: {
                                color: _vm.ICONS.CHECK_SQUARE.color,
                                size: 20,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "actions",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "b-button",
                          {
                            staticClass: "btn bg-transparent border-0 ml-1",
                            attrs: { size: "sm" },
                            on: {
                              click: function ($event) {
                                return _vm.rowEdit(item)
                              },
                            },
                          },
                          [
                            _c(_vm.getLucideIcon(_vm.ICONS.EDIT.name), {
                              tag: "component",
                              attrs: { color: _vm.ICONS.EDIT.color, size: 20 },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "salary",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-row align-items-center justify-content-end w-100",
                          },
                          [
                            _c("salary-log", { attrs: { item: item } }),
                            _c(
                              "div",
                              { staticStyle: { "margin-top": "2px" } },
                              [_vm._v(_vm._s(_vm.rendCurrency(item.salary)))]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "encodedBy",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-row align-items-center justify-content-end w-100",
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t" +
                                _vm._s(_vm.encodedBy(item)) +
                                "\n\t\t\t\t"
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                3127132119
              ),
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }