var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _vm.Timesheets
        ? _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "12", xl: "12" } }, [
                _vm.MyTSObj.length === 0
                  ? _c("div", { staticClass: "empty-state" }, [
                      _c("p", [
                        _vm._v(
                          _vm._s(this.FormMSG(10, "No timesheet to validate."))
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.$screen.width < 992
                  ? _c(
                      "div",
                      [
                        _c(
                          "b-overlay",
                          {
                            attrs: {
                              show: _vm.loadingMobile,
                              opacity: "0.75",
                              rounded: "lg",
                            },
                            on: {
                              "update:show": function ($event) {
                                _vm.loadingMobile = $event
                              },
                            },
                          },
                          [
                            _c("MobileViewsValidateTs", {
                              attrs: {
                                "time-sheets": _vm.MyTSObj,
                                fields: _vm.tsFields,
                              },
                              on: {
                                "validate-item": _vm.validateItem,
                                "mobile-view-validate-timesheet:clicked":
                                  _vm.rowClicked,
                                "mobile-view-validate-timesheet:validated":
                                  _vm.validateMobileItem,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      {
                        staticClass:
                          "container-layout details-document-package p-0",
                      },
                      [
                        _c(
                          "b-overlay",
                          {
                            attrs: {
                              show: _vm.isLoading,
                              opacity: "0.85",
                              rounded: "lg",
                            },
                            on: {
                              "update:show": function ($event) {
                                _vm.isLoading = $event
                              },
                            },
                          },
                          _vm._l(_vm.MyTSObj, function (oneItem) {
                            return _c(
                              "b-card",
                              {
                                key: oneItem.depName,
                                staticClass: "card-border-blue-light",
                                attrs: { "no-body": "" },
                              },
                              [
                                _c(
                                  "b-row",
                                  {
                                    staticClass:
                                      "back-with-title cursor-pointer d-flex align-items-center pb-2 pl-0",
                                    class: { "mt-16": _vm.$isPwa() },
                                  },
                                  [
                                    _c("b-col", { attrs: { cols: "6" } }, [
                                      _c(
                                        "h2",
                                        {
                                          staticClass:
                                            "d-flex align-items-center",
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(_vm._s(oneItem.depName)),
                                          ]),
                                          _c(
                                            "b-badge",
                                            {
                                              staticClass: "ml-2",
                                              attrs: { pill: "" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(oneItem.numTsForDep)
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c("b-col", { attrs: { cols: "6" } }, [
                                      _c(
                                        "div",
                                        { staticClass: "card-header-actions" },
                                        [
                                          _c(
                                            "b-link",
                                            {
                                              directives: [
                                                {
                                                  name: "b-toggle",
                                                  rawName: "v-b-toggle",
                                                  value: oneItem.depName,
                                                  expression: "oneItem.depName",
                                                },
                                              ],
                                              staticClass:
                                                "card-header-action btn-minimize text-white",
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "icon-arrow-down",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-collapse",
                                  {
                                    attrs: { visible: "", id: oneItem.depName },
                                  },
                                  [
                                    _c(
                                      "b-card-body",
                                      _vm._l(oneItem.users, function (oneUser) {
                                        return _c(
                                          "div",
                                          {
                                            key: oneUser.fullName,
                                            staticClass: "pb-3",
                                          },
                                          [
                                            _c(
                                              "h5",
                                              {
                                                staticClass:
                                                  "d-flex align-items-center",
                                              },
                                              [
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(oneUser.fullName) +
                                                      " - " +
                                                      _vm._s(
                                                        oneUser.functionName
                                                      )
                                                  ),
                                                ]),
                                                _c(
                                                  "b-badge",
                                                  {
                                                    staticClass: "ml-2",
                                                    attrs: { pill: "" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        oneUser.numTsForUser
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c("b-table", {
                                              attrs: {
                                                hover: _vm.hover,
                                                responsive: "sm",
                                                items: oneUser.ts,
                                                "text-variant": "white",
                                                fields: _vm.tsFields,
                                                "current-page": _vm.currentPage,
                                                "sticky-header": "700px",
                                                "per-page": _vm.perPage,
                                                "head-variant": _vm.hv,
                                                bordered: "",
                                                small: "",
                                              },
                                              on: {
                                                "row-clicked": _vm.rowClicked,
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "cell(validatedStatus)",
                                                    fn: function (data) {
                                                      return [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "wrap-status",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                class: `status ${_vm.classStatus(
                                                                  data.item
                                                                    .validated
                                                                )}`,
                                                                staticStyle: {
                                                                  "font-size":
                                                                    "0.7rem",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      data.item
                                                                        .validatedStatus
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                  {
                                                    key: "cell(encodedBy)",
                                                    fn: function (data) {
                                                      return [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex flex-row justify-content-center align-items-center w-100",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                _vm._s(
                                                                  _vm.encodedBy(
                                                                    data.item
                                                                  )
                                                                ) +
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                  {
                                                    key: "cell(val)",
                                                    fn: function (data) {
                                                      return [
                                                        _c(
                                                          "b-button",
                                                          {
                                                            staticClass:
                                                              "btn bg-transparent border-0 ml-1",
                                                            attrs: {
                                                              size: "sm",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.validateItem(
                                                                  data.item.id
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                _vm.ICONS
                                                                  .CHECK_SQUARE
                                                                  .name
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  color:
                                                                    _vm.ICONS
                                                                      .CHECK_SQUARE
                                                                      .color,
                                                                  size: 20,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                  {
                                                    key: "cell(salary)",
                                                    fn: function (data) {
                                                      return [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex flex-row align-items-center justify-content-end w-100",
                                                          },
                                                          [
                                                            _c("salary-log", {
                                                              attrs: {
                                                                item: data.item,
                                                              },
                                                            }),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  "margin-top":
                                                                    "2px",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.rendCurrency(
                                                                      data.item
                                                                        .salary
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ],
                                          1
                                        )
                                      }),
                                      0
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
              ]),
              _c(
                "b-modal",
                {
                  staticClass: "modal-success",
                  attrs: {
                    "header-class": "header-class-modal-doc-package",
                    title: _vm.FormMSG(11, "Success!"),
                    "ok-variant": "success",
                    "ok-only": "",
                  },
                  on: {
                    ok: function ($event) {
                      _vm.successModal = false
                    },
                  },
                  model: {
                    value: _vm.successModal,
                    callback: function ($$v) {
                      _vm.successModal = $$v
                    },
                    expression: "successModal",
                  },
                },
                [
                  _vm._v(
                    "\n\t\t\t" +
                      _vm._s(
                        this.FormMSG(12, "The timesheet has been validated!")
                      ) +
                      "\n\t\t"
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }