var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "footer d-flex flex-row align-items-center" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.toggleDetail,
              expression: "!toggleDetail",
            },
          ],
          staticClass: "label-footer",
        },
        [
          _c("div", { staticClass: "details" }, [
            _c("div", { staticClass: "number" }, [
              _vm._v(_vm._s(_vm.user.numTsForUser)),
            ]),
            _c("div", { staticClass: "full-name" }, [
              _vm._v(_vm._s(_vm.user.fullName)),
            ]),
            _c("div", { staticClass: "text-truncate" }, [
              _vm._v(_vm._s(_vm.user.functionName)),
            ]),
          ]),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "actions inline-flex justify-content-end",
          style: `width: ${!_vm.toggleDetail ? "40%" : "100%"}`,
        },
        [
          _c(
            "div",
            {
              staticClass: "cursor-pointer second-col w-100",
              on: { click: _vm.handleToggleDetail },
            },
            [
              _c("span", { staticClass: "mr-1" }, [
                _vm._v(
                  _vm._s(
                    !_vm.toggleDetail
                      ? _vm.FormMSG(1, "Show details")
                      : _vm.FormMSG(2, "Hide details")
                  )
                ),
              ]),
              !_vm.toggleDetail
                ? _c(
                    "span",
                    [
                      _c(_vm.getLucideIcon(_vm.ICONS.CHEVRON_DOWN.name), {
                        tag: "component",
                        attrs: { size: 18 },
                      }),
                    ],
                    1
                  )
                : _c(
                    "span",
                    [
                      _c(_vm.getLucideIcon(_vm.ICONS.CHEVRON_UP.name), {
                        tag: "component",
                        attrs: { size: 18 },
                      }),
                    ],
                    1
                  ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }